import React from "react";

import { graphql } from "gatsby";

import Layout from "../components/Layout";

import { Helmet } from "react-helmet";

import { GatsbyImage } from "gatsby-plugin-image";

const PraxisPage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Helmet>
        <html lang="de" />
        <title>Gastroenterologen Dres. Seutter & Flüeck Praxisteam</title>
        <meta
          name="description"
          content="Hier stellen wir uns und unser Praxisteam vor"
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta
          property="og:title"
          content="Gastroenterologen Dres. Seutter & Flüeck Praxisteam"
        />
        <meta
          name="og:description"
          content="Hier stellen wir uns und unser Praxisteam vor"
        />
        <meta property="og:url" content="/praxisteam" />
      </Helmet>
      <div
        style={{
          backgroundImage:
            "linear-gradient(90deg, rgba(0,34,82) 0%, rgba(0,106,255) 100%), radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0.5) 100%)",
          backgroundBlendMode: "multiply",
        }}
      >
        <div class="px-20p sm:px-10">
          <div class="max-w-900p mx-auto text-white py-35p sm:py-60p">
            <h1 class="font-bold text-15152 sm:text-24242">
              {frontmatter.title}
            </h1>
          </div>
        </div>
      </div>
      <div class="py-50p sm:py-80p px-20p sm:px-10">
        <div class="max-w-900p mx-auto text-09697 sm:text-10909 text-custom-grey space-y-50p sm:space-y-80p">
          {frontmatter.doctors.members &&
            frontmatter.doctors.members.length !== 0 && (
              <div>
                <div class="w-max">
                  <h2 class="text-primary font-bold text-15152 sm:text-18182 mb-15p sm:mb-20p">
                    {frontmatter.doctors.title}
                  </h2>
                  <div class="h-[0.175rem] bg-secondary rounded-full mb-35p sm:mb-60p" />
                </div>
                <div class="space-y-35p sm:space-y-60p">
                  {frontmatter.doctors.members.map((el, index) => (
                    <div class="flex flex-col sm:flex-row">
                      <div
                        id={index+1}
                        class="w-[13.75rem] sm:w-60 mx-auto mb-35p sm:mb-0"
                      >
                        <GatsbyImage
                          image={
                            el.imageObject.image.childImageSharp.gatsbyImageData
                          }
                          alt={el.imageObject.alt}
                        />
                      </div>
                      <div class="sm:sm:w-1/2">
                        <h3 class="text-12121 sm:text-15152 font-bold text-primary pb-15p sm:pb-20p">
                          {el.name}
                        </h3>
                        {el.cv && el.cv.length !== 0 && (
                          <React.Fragment>
                            <h4 class="text-09697 sm:text-12121 font-medium text-secondary pb-15p sm:pb-20p">
                              {frontmatter.doctors.cvTitle}
                            </h4>
                            <ul class="list-none space-y-15p sm:space-y-20p mb-15p sm:mb-20p">
                              {el.cv.map((el) => (
                                <li>{el}</li>
                              ))}
                            </ul>
                          </React.Fragment>
                        )}
                        {el.memberships && el.memberships.length !== 0 && (
                          <React.Fragment>
                            <h4 class="text-09697 sm:text-12121 font-medium text-secondary mb-15p sm:mb-20p">
                              {frontmatter.doctors.membershipsTitle}
                            </h4>
                            <ul className="list-none text-lightblue">
                              {el.memberships.map((el) => (
                                <li>
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={el.link}
                                  >
                                    {el.membership}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          {frontmatter.reception.members &&
            frontmatter.reception.members.length !== 0 && (
              <div id="team">
                <div class="w-max">
                  <h2 class="text-primary font-bold text-15152 sm:text-18182 mb-15p sm:mb-20p">
                    {frontmatter.reception.title}
                  </h2>
                  <div class="h-[0.175rem] bg-secondary rounded-full" />
                </div>
                <div class="flex flex-col sm:flex-row flex-wrap justify-evenly">
                  {frontmatter.reception.members.map((el) => (
                    <div class="sm:w-1/2">
                      <div class="w-[13.75rem] sm:w-60 mx-auto mt-35p sm:mt-60p">
                        <GatsbyImage
                          class="mb-15p sm:mb-20p"
                          image={
                            el.imageObject.image.childImageSharp.gatsbyImageData
                          }
                          alt={el.imageObject.alt}
                        />
                        <h3 class="text-12121 sm:text-15152 font-bold text-primary mb-15p sm:mb-20p">
                          {el.name}
                        </h3>
                        <ul class="list-none space-y-15p sm:space-y-20p">
                          {el.activities.map((el) => (
                            <li>{el}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          {frontmatter.endoscopy.members &&
            frontmatter.endoscopy.members.length !== 0 && (
              <div>
                <div class="w-max">
                  <h2 class="text-primary font-bold text-15152 sm:text-18182 mb-15p sm:mb-20p">
                    {frontmatter.endoscopy.title}
                  </h2>
                  <div class="h-[0.175rem] bg-secondary rounded-full" />
                </div>
                <div class="flex flex-col sm:flex-row flex-wrap justify-evenly">
                  {frontmatter.endoscopy.members.map((el) => (
                    <div class="sm:w-1/2">
                      <div class="w-[13.75rem] sm:w-60 mx-auto mt-35p sm:mt-60p">
                        <GatsbyImage
                          class="mb-15p sm:mb-20p"
                          image={
                            el.imageObject.image.childImageSharp.gatsbyImageData
                          }
                          alt={el.imageObject.alt}
                        />
                        <h3 class="text-12121 sm:text-15152 font-bold text-primary mb-15p sm:mb-20p">
                          {el.name}
                        </h3>
                        <ul class="list-none space-y-15p sm:space-y-20p">
                          {el.activities.map((el) => (
                            <li>{el}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          {frontmatter.trainees.show &&
            frontmatter.trainees.members &&
            frontmatter.trainees.members.length !== 0 && (
              <div>
                <div class="w-max">
                  <h2 class="text-primary font-bold text-15152 sm:text-18182 mb-15p sm:mb-20p">
                    {frontmatter.trainees.title}
                  </h2>
                  <div class="h-[0.175rem] bg-secondary rounded-full" />
                </div>
                <div class="flex flex-col sm:flex-row flex-wrap justify-evenly">
                  {frontmatter.trainees.members.map((el) => (
                    <div class="sm:w-1/2">
                      <div class="w-[13.75rem] sm:w-60 mx-auto mt-35p sm:mt-60p">
                        <GatsbyImage
                          class="mb-15p sm:mb-20p"
                          image={
                            el.imageObject.image.childImageSharp.gatsbyImageData
                          }
                          alt={el.imageObject.alt}
                        />
                        <h3 class="text-12121 sm:text-15152 font-bold text-primary mb-15p sm:mb-20p">
                          {el.name}
                        </h3>
                        <ul class="list-none space-y-15p sm:space-y-20p">
                          {el.activities.map((el) => (
                            <li>{el}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          {frontmatter.career.show &&
            frontmatter.career.positions &&
            frontmatter.career.positions.length !== 0 && (
              <div>
                <div class="w-max">
                  <h2
                    id="karriere"
                    class="text-primary font-bold text-15152 sm:text-18182 mb-15p sm:mb-20p"
                  >
                    {frontmatter.career.title}
                  </h2>
                  <div class="h-[0.175rem] bg-secondary rounded-full mb-35p sm:mb-60p" />
                </div>
                <div class="space-y-15p sm:space-y-20p">
                  <p>{frontmatter.career.description}</p>
                  {frontmatter.career.positions.map((el) => (
                    <p class="text-secondary font-medium">{el.position}</p>
                  ))}
                  <p>{frontmatter.career.description2}</p>
                  <div>
                    <a
                      class="text-lightblue"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`mailto:${frontmatter.career.careerEmail}`}
                    >
                      {frontmatter.career.emailCareer}
                    </a>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </Layout>
  );
};

export default PraxisPage;

export const query = graphql`
  {
    markdownRemark(frontmatter: { templateKey: { eq: "praxis-page" } }) {
      frontmatter {
        templateKey
        career {
          description
          show
          emailCareer
          description2
          title
          positions {
            position
          }
        }
        doctors {
          members {
            cv
            name
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData (width: 240)
                }
              }
            }
            memberships {
              link
              membership
            }
          }
          title
          cvTitle
          membershipsTitle
        }
        reception {
          title
          members {
            activities
            name
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData (width: 240)
                }
              }
            }
          }
        }
        trainees {
          show
          title
          members {
            activities
            name
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData (width: 240)
                }
              }
            }
          }
        }
        title
        endoscopy {
          title
          members {
            activities
            name
            imageObject {
              alt
              image {
                childImageSharp {
                  gatsbyImageData (width: 240)
                }
              }
            }
          }
        }
      }
    }
  }
`;
